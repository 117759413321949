import { useMutation, useQuery } from '@apollo/client';
import { Combobox, Listbox, Menu, Tab, Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpDownIcon,
  PaperClipIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserProfile } from 'interfaces';
import dynamic from 'next/dynamic';
import { Fragment, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import Avatar from 'components/atoms/avatar';
import BulkSelect from 'components/atoms/inputs/bulk-courses-multi-select';
import ClientSearch from 'components/organisms/client/search';

import classNames from 'utils/classnames';
import { EmailObject, emails } from 'utils/emails';
import { eventsData } from 'utils/events';

import { insertEmails } from 'queries/emails';
import { getAllEventDetails } from 'queries/events';
import { getDefinedMessages } from 'queries/leads';
import { insertSms } from 'queries/sms';

import { useBase } from 'providers/base';
import { useOrganization } from 'providers/organization';
import { useSession } from 'providers/session';
import { useUI } from 'providers/ui';

const schema = yup.object({
  customer_id: yup.string().optional(),
  subject: yup.string().optional(),
  message: yup.string().required(),
  type: yup.string().required(),
});

const NewClientModal = dynamic(
  () => import('components/organisms/client/new-client-modal'),
);

const Attachments = dynamic(
  () => import('components/molecules/template/attachments'),
);

const Modal = dynamic(() => import('components/molecules/modal'));

type Form = yup.InferType<typeof schema>;

type DefinedMessages = {
  defined_messages: {
    id: string;
    title: string;
    subject: string;
    content: string;
  }[];
};

const categories = ['Email', 'SMS'];

export default function NewContactModal({
  users,
  template,
  onClose,
  refetch,
}: {
  users?: UserProfile[];
  template?: any;
  onClose: () => void;
  refetch?: () => void;
}) {
  const { clients } = useBase();
  const ui = useUI();
  const [insert] = useMutation(insertEmails);
  const [insertText] = useMutation(insertSms);
  const { claims } = useSession();
  const { team } = useOrganization();

  const role = claims['x-hasura-role'];
  const userId = claims['x-hasura-user-id'];
  const upperUser = role === 'manager' || role === 'owner';
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);

  const [state, setState] = useState({
    selectedEvents: [],
    selectedEventsId: [],
    assets: [],
    selectedEventsTitle: [],
    selectedEventsObject: [],
    selectedClients: [],
    selectedEmail: null,
    newClient: false,
    attachments: false,
    status: '',
    isLoading: false,
    edit: false,
  });

  useEffect(() => {
    if (template && template.subject && template.content) {
      setValue('subject', template.subject);
      setValue('message', template.content);
      setState((s) => ({ ...s, edit: true }));
      if (template.assets && template.assets.length > 0) {
        setState((s) => ({ ...s, assets: template.assets }));
      }
    } else {
      setValue('subject', '');
      setValue('message', '');
      setState((s) => ({ ...s, edit: false, assets: [] }));
    }
  }, [template]);

  useEffect(() => {
    if (users && Array.isArray(users) && users?.length > 0) {
      setState((s) => ({ ...s, selectedClients: users }));
    }
  }, [users]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors },
  } = useForm<Form>({
    mode: 'all',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      type: 'EMAIL',
    },
  });

  const { data: eventDetails } = useQuery(getAllEventDetails, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setState((s) => ({
      ...s,
      selectedEventsId: state.selectedEvents.map((event) => event.id),
    }));
  }, [state.selectedEvents]);

  useEffect(() => {
    setState((s) => ({
      ...s,
      selectedEventsTitle: state.selectedEvents.map((event) => event.title),
    }));
  }, [state.selectedEvents]);

  useEffect(() => {
    setState((s) => ({
      ...s,
      selectedEventsObject: state.selectedEvents.map((event) =>
        [event.id, event.title].reduce(
          (ac, a) => ({
            ...ac,
            eventTitle: event.title,
            eventUrl: eventsData.find((eventData) => event.id === eventData.id)
              ?.url,
            eventImage: eventsData.find(
              (eventData) => event.id === eventData.id,
            )?.source,
          }),
          {},
        ),
      ),
    }));
  }, [state.selectedEvents]);

  const onSubmit = async (data: Form) => {
    try {
      setState((s) => ({ ...s, isLoading: true }));

      if (data.type === 'EMAIL') {
        const fullData =
          state.selectedClients &&
          state.selectedClients.length > 0 &&
          state.selectedClients.map((client) => {
            return {
              message: data.message.replace('{name}', client.name),
              subject: data.subject,
              from: state.selectedEmail.email,
              employee_id: selectedEmployee
                ? selectedEmployee?.user?.id
                : userId,
              assets:
                state.assets && state.assets.length > 0 ? state.assets : [],
              department: selectedDepartment ? selectedDepartment : null,
              modules: {
                physical: {
                  ids: state.selectedEventsId,
                  titles: state.selectedEventsTitle,
                  events: state.selectedEventsObject,
                },
              },
              status: state.status,
              user_id: client?.id,
            };
          });

        const response = await insert({
          variables: {
            data: fullData,
          },
        });
      } else if (data.type === 'SMS') {
        const fullData =
          state.selectedClients &&
          state.selectedClients.length > 0 &&
          state.selectedClients.map((client) => {
            return {
              message: data.message.replace('{name}', client.name),
              status: state.status,
              user_id: client?.id,
            };
          });

        const response = await insertText({
          variables: {
            data: fullData,
          },
        });
      }

      reset();
      setState((s) => ({
        ...s,
        selectedEvents: [],
        selectedEventsId: [],
        selectedEventsTitle: [],
        selectedEventsObject: [],
        status: '',
        isLoading: false,
      }));
      onClose();
      refetch && refetch();
      ui.setToast(true, {
        type: 'success',
        title: 'Success',
        message: `Successfully sent ${data.type.toLocaleLowerCase()}`,
      });
    } catch (err) {
      console.log(err, 'SOMETHING error');
      setState((s) => ({ ...s, isLoading: false }));
      ui.setToast(true, {
        type: 'error',
        title: `Failed to send ${data.type.toLocaleLowerCase()}`,
        message: '',
      });
    }
  };

  const { data: presetMessages } = useQuery<DefinedMessages>(
    getDefinedMessages,
    {
      variables: {
        orderBy: { title: 'asc' },
        where: {
          _and: [{ type: { _eq: 'Template' } }],
        },
      },
      fetchPolicy: 'network-only',
    },
  );

  const title = 'New Contact';

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-2 pr-3 w-[600px]">
        <div className="-mt-8 pr-6 mb-4 flex items-center w-full justify-between">
          <h2 className="font-semibold text-xl">{title}</h2>
          {!users && (
            <div className="flex justify-end">
              <button
                type="button"
                className="transition-all rounded-md border border-gray-300 bg-white py-1 px-2 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                onClick={() => setState((s) => ({ ...s, newClient: true }))}
              >
                Add client
              </button>
            </div>
          )}
        </div>
        <Tab.Group>
          <Tab.List className="flex space-x-1 bg-purple-600 p-2 px-2.5 w-full rounded-xl mt-2">
            {categories.map((category) => (
              <Tab
                key={category}
                onClick={() => {
                  setValue('type', category.toUpperCase());
                }}
                className={({ selected }) =>
                  classNames(
                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-purple-400 focus:outline-none focus:ring-2',
                    selected
                      ? 'bg-white shadow text-purple-700 hover:text-purple-900'
                      : 'text-purple-100 hover:bg-white/[0.12] hover:text-white',
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="h-full w-full">
            <Tab.Panel className="bg-white w-full flex-1 h-full">
              {clients && (
                <div className="">
                  <Combobox
                    value={state.selectedClients}
                    multiple
                    onChange={(e) =>
                      setState((s) => ({ ...s, selectedClients: e }))
                    }
                  >
                    <div className="py-2 pt-4">
                      <div className="flex flex-row items-center space-x-3">
                        <p className="px-4 text-sm text-gray-600">To:</p>
                        <ClientSearch
                          selectedClients={state.selectedClients}
                          setSelectedClients={(clients) =>
                            setState((s) => ({
                              ...s,
                              selectedClients: clients,
                            }))
                          }
                          removeClient={() =>
                            setState((s) => ({ ...s, selectedClient: [] }))
                          }
                        />
                      </div>
                    </div>
                  </Combobox>
                  {errors && errors.customer_id ? (
                    <span className="px-4 text-xs text-red-600">
                      Student Is Required To Create Email
                    </span>
                  ) : null}
                </div>
              )}
              <div className=" mt-1">
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  className="focus:outline-none focus:ring-purple-500 rounded-md border focus:border-purple-500 block w-full sm:text-sm border-gray-300  truncate"
                  placeholder="Subject"
                  aria-describedby="student-subject"
                  {...register('subject')}
                />
              </div>

              <div className=" mt-2">
                <Listbox
                  value={state.selectedEmail}
                  onChange={(e) => {
                    const value = e && e.id ? e : null;
                    setState((s) => ({ ...s, selectedEmail: value }));
                  }}
                >
                  <div className="flex flex-row items-center space-x-3 z-10">
                    <p className="px-4 text-sm text-gray-600">From:</p>
                    <SelectEmail selectedEmail={state.selectedEmail} />
                  </div>
                </Listbox>
              </div>

              <div className="mb-6 flex w-full flex-1 gap-2 mt-2 items-center">
                <BulkSelect
                  placeholder="Recommend a course"
                  options={eventDetails && eventDetails.event_detail}
                  selectedOptions={state.selectedEvents}
                  setSelectedOptions={(e) =>
                    setState((s) => ({
                      ...s,
                      selectedEvents: e,
                    }))
                  }
                />
              </div>
              <div className="mb-6">
                <div className="flex justify-between items-center mb-1">
                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                  >
                    Your message
                  </label>
                  <div className="z-10 flex space-x-2">
                    <div
                      onClick={() =>
                        setState((s) => ({ ...s, attachments: true }))
                      }
                      className="btn-primary space-x-2 flex-row items-center cursor-pointer"
                    >
                      <PaperClipIcon className="w-5 h-5" aria-hidden="true" />
                      <p>Add Attachment</p>
                    </div>

                    <Menu
                      as="div"
                      className="relative inline-block text-left ml-2"
                    >
                      <div>
                        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                          Presets
                          <ChevronDownIcon
                            className="-mr-1 ml-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 w-56 h-56 top-12 overflow-y-scroll origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {presetMessages &&
                            presetMessages.defined_messages &&
                            presetMessages.defined_messages.length > 0 &&
                            presetMessages.defined_messages.map((message) => {
                              return (
                                <div className="" key={message.id}>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() => {
                                          setValue('subject', message.subject, {
                                            shouldDirty: true,
                                            shouldTouch: true,
                                            shouldValidate: true,
                                          });
                                          setValue('message', message.content, {
                                            shouldDirty: true,
                                            shouldTouch: true,
                                            shouldValidate: true,
                                          });
                                        }}
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm',
                                        )}
                                      >
                                        {message.title}
                                      </div>
                                    )}
                                  </Menu.Item>
                                </div>
                              );
                            })}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                    {false ? (
                      <Menu
                        as="div"
                        className="relative inline-block text-left ml-2"
                      >
                        <div>
                          <Menu.Button className="inline-flex w-full justify-center whitespace-nowrap rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                            {selectedEmployee
                              ? `${selectedEmployee.user.name}`
                              : 'Signed By'}
                            <ChevronDownIcon
                              className="-mr-1 ml-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 w-56 h-56 top-12 overflow-y-scroll origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {team &&
                              team.length > 0 &&
                              team
                                .filter((employee) => employee.active)
                                .map((employee) => (
                                  <Menu.Item key={employee.id}>
                                    <div
                                      onClick={() => {
                                        setSelectedEmployee(employee);
                                      }}
                                      className={classNames(
                                        `flex items-center text-xxs xl:text-xs cursor-default select-none relative py-2 pl-8 pr-4 ${
                                          selectedEmployee &&
                                          selectedEmployee.user &&
                                          selectedEmployee.user.id ===
                                            employee.user.id
                                            ? `text-purple-900 bg-purple-100`
                                            : 'text-gray-900'
                                        }`,
                                      )}
                                    >
                                      {employee.user.memberAvatar &&
                                        employee.user.memberAvatar[0] &&
                                        employee.user.memberAvatar[0].asset && (
                                          <div className="mr-2">
                                            <Avatar
                                              size={{
                                                width: 6,
                                                height: 6,
                                              }}
                                              layout="fill"
                                              src={
                                                employee.user.memberAvatar[0]
                                                  .asset.url
                                              }
                                            />
                                          </div>
                                        )}
                                      <div className="">
                                        <span>
                                          {employee.user.name}{' '}
                                          {employee.user?.surname}
                                        </span>
                                        <p className="opacity-50">
                                          {employee?.jobTitle}
                                        </p>
                                      </div>
                                      {selectedEmployee &&
                                      selectedEmployee.user &&
                                      selectedEmployee.user.id ===
                                        employee.user.id ? (
                                        <span
                                          className={`absolute inset-y-0 -left-1 flex items-center pl-3 text-purple-600`}
                                        >
                                          <CheckIcon
                                            className="w-4 h-4"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </div>
                                  </Menu.Item>
                                ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : null}
                    {upperUser ? (
                      <Menu
                        as="div"
                        className="relative inline-block text-left ml-2"
                      >
                        <div>
                          <Menu.Button className="inline-flex w-full justify-center whitespace-nowrap rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                            {selectedDepartment
                              ? selectedDepartment
                              : 'Signed By'}
                            <ChevronDownIcon
                              className="-mr-1 ml-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 w-56 top-12 overflow-y-scroll origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {departments.map((department) => (
                              <Menu.Item key={department}>
                                <div
                                  onClick={() => {
                                    if (department.toLowerCase() === 'none') {
                                      setSelectedDepartment(null);
                                    } else {
                                      setSelectedDepartment(department);
                                    }
                                  }}
                                  className={classNames(
                                    `flex items-center text-xxs xl:text-xs cursor-default select-none relative py-2 pl-8 pr-4 ${
                                      selectedDepartment === department
                                        ? `text-purple-900 bg-purple-100`
                                        : 'text-gray-900'
                                    }`,
                                  )}
                                >
                                  <div className="">
                                    <span>{department}</span>
                                    {/* <p className="opacity-50">
                                          {employee?.jobTitle}
                                        </p> */}
                                  </div>
                                  {selectedDepartment === department ? (
                                    <span
                                      className={`absolute inset-y-0 -left-1 flex items-center pl-3 text-purple-600`}
                                    >
                                      <CheckIcon
                                        className="w-4 h-4"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </div>
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : null}
                  </div>
                </div>

                <Controller
                  control={control}
                  name="message"
                  render={({ field }) => (
                    <textarea
                      id="emailMessage"
                      name="content"
                      rows={10}
                      className="shadow-sm block w-full focus:ring-purple-500 focus:border-purple-500 sm:text-sm border-gray-300 rounded"
                      placeholder="Write your message here. Press enter to create breaks between paragraphs."
                      {...field}
                    />
                  )}
                />

                {state.assets && state.assets.length > 0 && (
                  <div className="space-y-2 mt-4">
                    <p className="text-sm font-medium leading-6 text-gray-600">
                      Attachments
                    </p>
                    {state.assets.map((asset) => {
                      return (
                        <li
                          key={asset?.name}
                          className="flex p-3 border w-full justify-between border-gray-300 rounded-md"
                        >
                          <div className="min-w-0">
                            <p className="text-sm font-medium leading-6 text-gray6900">
                              {asset.name}
                            </p>
                          </div>

                          <button
                            type="button"
                            onClick={() => {
                              setState((s) => ({
                                ...s,
                                assets: s.assets.filter(
                                  (a) => a.name !== asset.name,
                                ),
                              }));
                            }}
                            className="ml-4"
                          >
                            <TrashIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </button>
                        </li>
                      );
                    })}
                  </div>
                )}
              </div>
            </Tab.Panel>
            <Tab.Panel className="bg-white w-full flex-1 h-full">
              {clients && (
                <div className="">
                  <Combobox
                    value={state.selectedClients}
                    multiple
                    onChange={(e) =>
                      setState((s) => ({ ...s, selectedClients: e }))
                    }
                  >
                    <div className="py-2 pt-4">
                      <div className="flex flex-row items-center space-x-3">
                        <p className="px-4 text-sm text-gray-600">To:</p>
                        <ClientSearch
                          selectedClients={state.selectedClients}
                          setSelectedClients={(clients) =>
                            setState((s) => ({
                              ...s,
                              selectedClients: clients,
                            }))
                          }
                          removeClient={() =>
                            setState((s) => ({ ...s, selectedClient: [] }))
                          }
                        />
                      </div>
                    </div>
                  </Combobox>
                  {errors && errors.customer_id ? (
                    <span className="px-4 text-xs text-red-600">
                      Student Is Required To Create Email
                    </span>
                  ) : null}
                </div>
              )}

              <div className="mb-6">
                <div className="flex justify-between items-center mb-1">
                  <label
                    htmlFor="message"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                  >
                    Your message
                  </label>
                  <div className="z-10 flex space-x-2">
                    <Menu
                      as="div"
                      className="relative inline-block text-left ml-2"
                    >
                      <div>
                        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                          Presets
                          <ChevronDownIcon
                            className="-mr-1 ml-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 w-56 h-56 top-12 overflow-y-scroll origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {presetMessages &&
                            presetMessages.defined_messages &&
                            presetMessages.defined_messages.map((message) => {
                              return (
                                <div className="" key={message.id}>
                                  <Menu.Item>
                                    {({ active }) => (
                                      <div
                                        onClick={() => {
                                          setValue('subject', message.subject, {
                                            shouldDirty: true,
                                            shouldTouch: true,
                                            shouldValidate: true,
                                          });
                                          setValue('message', message.content, {
                                            shouldDirty: true,
                                            shouldTouch: true,
                                            shouldValidate: true,
                                          });
                                        }}
                                        className={classNames(
                                          active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700',
                                          'block px-4 py-2 text-sm',
                                        )}
                                      >
                                        {message.title}
                                      </div>
                                    )}
                                  </Menu.Item>
                                </div>
                              );
                            })}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>

                <Controller
                  control={control}
                  name="message"
                  render={({ field }) => (
                    <textarea
                      id="emailMessage"
                      name="content"
                      rows={10}
                      className="shadow-sm block w-full focus:ring-purple-500 focus:border-purple-500 sm:text-sm border-gray-300 rounded"
                      placeholder="Write your message here. Press enter to create breaks between paragraphs."
                      {...field}
                    />
                  )}
                />
                {state.assets && state.assets.length > 0 && (
                  <div className="space-y-2 mt-4">
                    <p className="text-sm font-medium leading-6 text-gray-600">
                      Attachments
                    </p>
                    {state.assets.map((asset) => {
                      return (
                        <li
                          key={asset.name}
                          className="flex p-3 border w-full justify-between border-gray-300 rounded-md"
                        >
                          <div className="min-w-0">
                            <p className="text-sm font-medium leading-6 text-gray6900">
                              {asset.name}
                            </p>
                          </div>

                          <button
                            type="button"
                            onClick={() => {
                              setState((s) => ({
                                ...s,
                                assets: s.assets.filter(
                                  (a) => a.name !== asset.name,
                                ),
                              }));
                            }}
                            className="ml-4"
                          >
                            <TrashIcon
                              className="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </button>
                        </li>
                      );
                    })}
                  </div>
                )}
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        <div className="grid grid-cols-2 gap-2 mt-4">
          <button
            disabled={state.isLoading || state?.selectedClients?.length === 0}
            onClick={() => setState((s) => ({ ...s, status: 'DRAFT' }))}
            type="submit"
            className="btn-secondary inline-block w-30"
          >
            Save As Draft
          </button>
          <button
            disabled={state.isLoading || state?.selectedClients?.length === 0}
            onClick={() => setState((s) => ({ ...s, status: 'PENDING' }))}
            type="submit"
            className="btn-primary inline-block w-30"
          >
            Send
          </button>
        </div>
      </form>
      {state.attachments && (
        <Modal
          open={state.attachments}
          close={() => setState((s) => ({ ...s, attachments: false }))}
        >
          <Attachments
            addAttachment={(e) =>
              setState((s) => ({ ...s, assets: [...s.assets, e] }))
            }
          />
        </Modal>
      )}
      {state.newClient && (
        <Modal
          open={state.newClient}
          close={() => setState((s) => ({ ...s, newClient: false }))}
        >
          <NewClientModal
            prefill={null}
            onComplete={() => {
              setState((s) => ({
                ...s,
                newClient: false,
              }));
            }}
          />
        </Modal>
      )}
    </>
  );
}

const SelectEmail = ({ selectedEmail }: { selectedEmail: EmailObject }) => {
  return (
    <div className="relative mt-1 w-full">
      <div className="relative w-full">
        <Listbox.Button className="relative w-full py-2 pl-2 pr-10 text-left bg-white rounded-lg cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white  border border-gray-300 focus-visible:ring-offset-purple-500 focus-visible:ring-offset-2 focus-visible:border-purple-500 text-sm">
          <span className="block truncate">
            {selectedEmail ? selectedEmail.email : 'Choose Email'}
          </span>
          <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-purple-600"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
      </div>
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options className="z-50 absolute w-full py-1 mt-1 overflow-auto bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none text-xxs xl:text-xs">
          {emails &&
            emails.length > 0 &&
            emails.map((person) => (
              <Listbox.Option
                key={person.id}
                className={({ active }) =>
                  `cursor-default select-none relative py-2 pl-7 pr-4 ${
                    active ? 'text-white bg-purple-600' : 'text-gray-900'
                  }`
                }
                value={person}
              >
                {({ selected, active }) => (
                  <>
                    <span
                      className={`block capitalize truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {person.email}
                      <span className="lowercase"> ({person.email})</span>
                    </span>
                    {selected ? (
                      <span
                        className={`absolute inset-y-0 -left-1 flex items-center pl-3 ${
                          active ? 'text-white' : 'text-purple-600'
                        }`}
                      >
                        <CheckIcon className="w-4 h-4" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
        </Listbox.Options>
      </Transition>
    </div>
  );
};

const departments = [
  'Student Support',
  'Model Coordinator',
  'Management',
  'None',
];
