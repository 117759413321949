import { isAfter } from 'date-fns';

export function calculateEnrollment(
  enrollment: Enrollment,
  invoices?: Invoice[],
) {
  const vatFree =
    enrollment?.discounts?.[0]?.discount?.code === 'VATFREE' ||
    enrollment?.discounts?.[0]?.discount?.code === '2025';

  const subTotal =
    enrollment && enrollment.metadata.reduce((a, b) => a + b.seat_price, 0);

  const discountAmount = vatFree
    ? 0
    : enrollment && enrollment.discounts.length > 0
      ? enrollment.discounts[0].discount.type === 'percent'
        ? (enrollment.discounts[0].discount.value / 100) * subTotal
        : enrollment.discounts[0].discount.value
      : 0;

  const paymentsAmount =
    enrollment && enrollment.payments && enrollment.payments.length > 0
      ? enrollment.payments
          .filter((x) => x.payment && x.payment.status === 'complete')
          .filter((x) => !x.payment.invoice_id)
          .reduce((a, b) => a + b.payment.amount, 0)
      : 0;

  const invoicesAmount =
    invoices && invoices.length > 0
      ? invoices
          .filter((x) => x.status === 'paid' || x.status === 'complete')
          .reduce((a, b) => a + b.due, 0)
      : 0;

  const paidAmount = invoicesAmount + paymentsAmount;

  const creditAmount =
    enrollment && enrollment.credits.length > 0
      ? enrollment.credits[0].credit.amount
      : 0;

  const realTotal = subTotal - discountAmount - creditAmount;

  const extrasTotal =
    enrollment && enrollment.metadata.length > 0
      ? subTotal - enrollment.metadata.reduce((a, b) => a + b.seat_price, 0)
      : 0;

  const after =
    enrollment &&
    isAfter(new Date(enrollment.created_at), new Date('2024-05-16 16:00:00'));

  const vat =
    enrollment?.discounts?.[0]?.discount?.code === 'VATFREE' ||
    enrollment?.discounts?.[0]?.discount?.code === '2025'
      ? 0
      : 0.2;

  return {
    paidAmount,
    subTotal,
    realTotal: after ? realTotal * (1 + vat) : 0,
    creditAmount,
    extrasTotal,
    discountAmount,
  };
}

type Invoice = any;
type Enrollment = any;
