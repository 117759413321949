import { useLazyQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import {
  ClockIcon,
  MicrophoneIcon,
  TagIcon,
} from '@heroicons/react/24/outline';
import {
  AtSymbolIcon,
  BellIcon,
  ChatBubbleBottomCenterIcon,
  CheckIcon,
  HandThumbUpIcon,
  PhoneArrowDownLeftIcon,
  PhoneArrowUpRightIcon,
  UserIcon,
} from '@heroicons/react/24/solid';
import { isBefore } from 'date-fns';
import format from 'date-fns/format';
import dynamic from 'next/dynamic';
import React, { useCallback, useEffect, useState } from 'react';

import Status from 'components/atoms/status';
import Label from 'components/label';
import CallComplaints from 'components/molecules/call-complaint-picker';
import AddComplaint from 'components/molecules/panels/complaints/add-complaint';
import SendAsset from 'components/molecules/send-asset';
import Message from 'components/organisms/leads/message';
import LeadNotes from 'components/organisms/leads/notes';

import { currency } from 'utils/formats';

import { getCallById, updateCall } from 'queries/calls';
import { addCallConversion } from 'queries/calls';
import {
  addComplaintCall,
  getClientComplaints,
  getComplaintCalls,
} from 'queries/complaints';
import { getEvents } from 'queries/events';
import { insertRegistration } from 'queries/registration';

import { useOrganization } from 'providers/organization';
import { useSession } from 'providers/session';
import { useUI } from 'providers/ui';

import SetReminder from './set-reminder';

const NewEnrollmentModal = dynamic(
  () => import('components/organisms/enrollment/new-enrollment'),
);

const eventTypes = {
  applied: { icon: UserIcon, bgColorClass: 'bg-gray-400' },
  advanced: { icon: HandThumbUpIcon, bgColorClass: 'bg-blue-500' },
  message: { icon: ChatBubbleBottomCenterIcon, bgColorClass: 'bg-purple-500' },
  completed: { icon: CheckIcon, bgColorClass: 'bg-green-600' },
};

enum ViewTypes {
  'messages',
  'notes',
}
const Modal = dynamic(() => import('components/molecules/modal'));

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const NewClientModal = dynamic(
  () => import('components/organisms/client/new-client-modal'),
);

export default function Calls({ callID, statusValues = null, columns = null }) {
  const org = useOrganization();
  const { user, claims } = useSession();
  const ui = useUI();

  const [view, setView] = useState(ViewTypes.notes);
  const [updateCallStatus] = useMutation(updateCall);
  const [createRegistration] = useMutation(insertRegistration);
  const [convertedCall] = useMutation(addCallConversion);

  console.log('this call', callID);
  const member = user && org.teamMember(claims['x-hasura-user-id']);

  const [state, setState] = useState({
    profile: false,
    actions: false,
    newClient: false,
    addToEventModal: false,
    reminderModal: false,
    assetModal: false,
    externalData: null,
    newEnrollment: false,
    complaintModal: false,
    selectedComplaint: null,
    newComplaint: false,
  });

  const action = useCallback(
    (user) => {
      ui.setCall(false, null);
      ui.setClient(true, user);
    },
    [ui.setClient],
  );

  const [editComplaint] = useMutation(addComplaintCall);

  const [getCallData, { data: callData, loading, error: callError }] =
    useLazyQuery(getCallById);

  const [getComplaintData, { data: complaintData }] =
    useLazyQuery(getClientComplaints);

  useEffect(() => {
    const id = callID.id || callID;
    getCallData({
      variables: {
        id,
      },
    });

    getComplaintData({
      variables: {
        id,
      },
    });
  }, [callID, state.newClient, state.addToEventModal, state.reminderModal]);

  useEffect(() => {
    if (callData) {
      console.log('load', callData);
      fetch('/api/call-details', {
        method: 'POST',
        body: JSON.stringify({
          id: callData.call.payload.CallSid || callData.call.payload.callSid,
        }),
      })
        .then((r) => r.json())
        .then((r) => setState((s) => ({ ...s, externalData: r })));
    }
  }, [callData]);

  console.log('externalData', state.externalData);

  // const addToEvent = async (data) => {
  //   const parsedData = JSON.parse(data.upcoming_event);

  //   try {
  //     await createRegistration({
  //       variables: {
  //         data: {
  //           user_id: callData.call.user_id,
  //           event_id: parsedData.id,
  //           status: 'PAID',
  //           type: 'STUDENT',
  //           session_id: null,
  //         },
  //       },
  //       refetchQueries: [
  //         // {
  //         //   query: getCallById,
  //         //   variables: {
  //         //     callID,
  //         //   },
  //         // },
  //         {
  //           query: getEvents,
  //           variables: {
  //             where: {
  //               start: {
  //                 _gte: startOfDay(new Date()),
  //               },
  //             },
  //           },
  //         },
  //       ],
  //     });

  //     await convertedCall({
  //       variables: {
  //         data: {
  //           value: parsedData.price,
  //           event: parsedData.id,
  //           call: callID,
  //         },
  //       },
  //     });
  //     setState({
  //       ...state,
  //       addToEventModal: false,
  //     });
  //     ui.setToast(true, {
  //       type: 'success',
  //       title: 'Added to event',
  //       message: '',
  //     });
  //   } catch (error) {
  //     ui.setToast(true, {
  //       type: 'error',
  //       title: 'Failed to add to event',
  //       message: '',
  //     });
  //   }
  // };

  const labels = callData?.call?.user?.user_labels;

  const messagesCount = callData?.call.contacts_aggregate.aggregate.count;
  const messagesTab =
    messagesCount > 0
      ? "Messages <span class='bg-purple-600 p-0.5 px-2 text-white rounded-full'>" +
        messagesCount +
        '</span>'
      : 'Messages';

  const notesCount = callData?.call.notes_aggregate.aggregate.count;
  const notesTab =
    notesCount > 0
      ? "Notes <span class='bg-purple-600 p-0.5 px-2 text-white rounded-full'>" +
        notesCount +
        '</span>'
      : 'Notes';

  const links = [
    {
      text: <div dangerouslySetInnerHTML={{ __html: notesTab }} />,
      label: 'Notes',
      type: ViewTypes.notes,
    },
    {
      text: <div dangerouslySetInnerHTML={{ __html: messagesTab }} />,
      label: 'Messages',
      type: ViewTypes.messages,
    },
  ];

  const notes = callData?.call?.notes;
  const messages = callData?.call?.messages;

  if (loading) {
    return (
      <div className="flex-1 flex justify-center items-center">
        <p>Loading</p>
      </div>
    );
  }

  if (!callData) {
    return null;
  }

  const timeline = [
    {
      id: '1',
      type: eventTypes.applied,
      content: 'Called on ',
      createdAt: new Date(callData.call.createdAt),
      date: format(new Date(callData.call.createdAt), 'LLL dd'),
      datetime: format(new Date(callData.call.createdAt), 'yyyy-MM-dd'),
    },
  ];

  if (callData.call.conversions && callData.call.conversions.length > 0) {
    callData.call.conversions.forEach((x) => {
      // return
      timeline.push({
        id: x.id,
        type: eventTypes.completed,
        content: `${x.user.name} booked ${callData.call.user.name} in ${
          x.eventByEvent.detail.title
        } with a value of ${currency(x.value)}`,
        createdAt: new Date(x.createdAt),
        date: format(new Date(x.createdAt), 'LLL dd'),
        datetime: format(new Date(x.createdAt), 'yyyy-MM-dd'),
      });
    });
  }

  if (messages && messages.length > 0) {
    messages.forEach((m) =>
      timeline.push({
        id: m.id,
        type: eventTypes.message,
        content: `${m.user.name} sent the client ${
          m.type === 'email' ? `an email` : `a text`
        }`,
        createdAt: new Date(m.createdAt),
        date: format(new Date(m.createdAt), 'LLL dd'),
        datetime: format(new Date(m.createdAt), 'yyyy-MM-dd'),
      }),
    );
  }

  timeline.sort((a, b) => {
    // @ts-ignore
    return b.createdAt - a.createdAt;
  });

  async function closeCall() {
    updateCallStatus({ variables: { id: callData.call.id, status: 'CLOSED' } });
  }

  async function openCall() {
    updateCallStatus({ variables: { id: callData.call.id, status: 'OPEN' } });
  }

  const changeStatus = async (status) => {
    try {
      await updateCallStatus({
        variables: { id: callData.call.id, status: status },
      });

      ui.setToast(true, {
        type: 'success',
        title:
          'Moved from ' +
          columns[callData.call.status]?.label +
          ' to ' +
          columns[status]?.label,
        message: '',
      });
    } catch (error) {
      ui.setToast(true, {
        type: 'error',
        title: 'Failed to change status',
        message: `${error}`,
      });
    }
  };

  const handleComplaint = (id) => {
    setState({
      ...state,
      selectedComplaint: id,
    });
  };

  const handleAddCall = async () => {
    try {
      await editComplaint({
        variables: {
          data: {
            call_id: callData.call.id,
            complaint_id: state.selectedComplaint,
          },
        },
        refetchQueries: [
          {
            query: getComplaintCalls,
            variables: {
              cid: state.selectedComplaint,
            },
          },
          {
            query: getClientComplaints,
            variables: {
              id: callID?.id || callID,
            },
          },
        ],
      });
      ui.setToast(true, {
        type: 'success',
        title: 'Added call to complaint',
        message: '',
      });
    } catch (err) {
      console.log(err);
      ui.setToast(true, {
        type: 'error',
        title: 'Failed to add call to complaint',
        message: '',
      });
    }
  };

  const reminderSection =
    callData &&
    callData.call &&
    isBefore(new Date(callData.call.due), new Date()) ? (
      <p className="pt-2 flex items-center text-md text-gray-900 mb-2 md:mb-0">
        <BellIcon
          className="flex-shrink-0 mr-1 h-4 w-4 text-red-500"
          aria-hidden="true"
        />
        <span className="mr-2 text-red-500">
          Overdue:{' '}
          <time dateTime={callData.call.due}>
            {' ' +
              format(new Date(callData.call.due), 'do MMM yyyy') +
              ' at ' +
              format(new Date(callData.call.due), 'p')}
          </time>
        </span>
      </p>
    ) : (
      <p className="pt-2 flex items-center text-md text-gray-900 mb-2 md:mb-0">
        <BellIcon
          className="flex-shrink-0 mr-1 h-4 w-4 text-purple-600"
          aria-hidden="true"
        />
        <span className="mr-2">
          Reminder:{' '}
          <time dateTime={callData.call.due}>
            {' ' +
              format(new Date(callData.call.due), 'do MMM yyyy') +
              ' at ' +
              format(new Date(callData.call.due), 'p')}
          </time>
        </span>
      </p>
    );

  const recordings = callID.payload ? callID.payload.recordings : [];

  console.log('callID', callID);
  console.log('recordings', recordings);

  return (
    <div className="bg-white h-full" id={callData.call.id}>
      <main className="py-6 mb-24">
        <div className="max-w-3xl lg:max-w-7xl mx-6"></div>

        <div className="bg-white border border-gray-300 max-w-3xl mx-6 lg:max-w-7xl p-4 rounded md:grid md:grid-cols-12">
          <div className="md:flex col-span-8 items-center">
            <div className="flex-shrink-0">
              <div className="relative">
                <span
                  className="absolute inset-0 shadow-inner rounded-full"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div className="">
              {callData.call.type === 'incoming' ? (
                <h1 className="text-2xl font-bold text-gray-900 md:mb-0 mb-2">
                  {callData.call.user
                    ? callData.call.user.name + ' ' + callData.call.user.surname
                    : (callData.call.payload.from ??
                      callData.call.payload.number)}
                </h1>
              ) : (
                <h1 className="text-2xl font-bold text-gray-900 md:mb-0 mb-2">
                  {callData.call.user
                    ? callData.call.user.name + ' ' + callData.call.user.surname
                    : (callData.call.payload.number ??
                      callData.call.payload.to)}
                </h1>
              )}
            </div>
          </div>
          <div className="md:flex col-span-4 md:space-x-3 space-y-2 md:space-y-0">
            {/* <div className="flex-1">
              <button
                onClick={(e) => {
                  chargeFee();
                }}
                type="button"
                className="btn-primary"
              >
                Charge
              </button>
            </div> */}
            <div className="flex-1">
              {callData.call.user ? (
                <button
                  onClick={() => setState({ ...state, newEnrollment: true })}
                  type="button"
                  className="btn-primary"
                >
                  Create Enrollment
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setState({ ...state, actions: false, newClient: true });
                  }}
                  type="button"
                  className="btn-primary"
                >
                  Register
                </button>
              )}
            </div>
            <select
              name="status"
              className="appearance-none block w-52 px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-light mb-2"
              defaultValue={callData.call.status}
              onChange={(e) => changeStatus(e.target.value)}
            >
              {statusValues &&
                statusValues.status.map((status) => {
                  return (
                    <option value={status.value} key={status.value}>
                      {columns[status.value]?.label}
                    </option>
                  );
                })}
            </select>
            {/* {callData.call.status !== "CLOSED" && (
              <div className="flex-1">
                <button
                  onClick={closeCall}
                  type="button"
                  className="btn-secondary"
                >
                  Close
                </button>
              </div>
            )} */}
            {callData.call.status === 'CLOSED' && (
              <div className="flex-1">
                <button
                  onClick={openCall}
                  type="button"
                  className="btn-secondary"
                >
                  Open
                </button>
              </div>
            )}
          </div>
        </div>

        <div className="mt-6 max-w-3xl mx-6 lg:max-w-7xl grid grid-cols-1 gap-6 lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <div>
              <div className="sm:hidden md:px-4">
                <label htmlFor="tabs" className="sr-only">
                  Select a tab
                </label>
                <select
                  // @ts-ignore
                  onChange={(e) => {
                    setView(ViewTypes[e.target.value]);
                  }}
                  id="tabs"
                  name="tabs"
                  className="block w-full focus:ring-purple-500 focus:border-purple-500 border-gray-300 rounded-md"
                >
                  {links.map((link, key) => {
                    return (
                      <option
                        value={ViewTypes[link.type]}
                        key={`tab-nav-${key}`}
                      >
                        {link.label || link.text}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="hidden sm:block">
                <nav
                  className="relative z-0 rounded border border-gray-300 flex divide-x divide-gray-300"
                  aria-label="Tabs"
                >
                  {links.map((link, key) => (
                    <button
                      key={`client-link-${key}`}
                      onClick={() => setView(link.type)}
                      className={[
                        'w-4/12 group relative min-w-0 lg:flex-1 overflow-hidden bg-white py-4 px-2 lg:px-4 text-xs font-semibold text-center hover:bg-gray-50 focus:z-10 focus:outline-none',
                        view === link.type
                          ? 'text-gray-900'
                          : 'text-gray-500 hover:text-gray-700',
                        key === 0 ? 'rounded-l' : '',
                        key === links.length - 1 ? 'rounded-r' : '',
                        link.type === ViewTypes.messages ||
                        link.type === ViewTypes.notes
                          ? 'col-span-3 lg:col-span-2'
                          : 'col-span-2',
                      ].join(' ')}
                      aria-current="page"
                    >
                      <span>{link.text}</span>
                      <span
                        aria-hidden="true"
                        className={`${
                          view === link.type
                            ? 'bg-purple-500'
                            : 'bg-transparent '
                        } absolute inset-x-0 bottom-0 h-0.5`}
                      ></span>
                    </button>
                  ))}
                </nav>
              </div>
            </div>

            {view === ViewTypes.messages && (
              <Message
                org={org}
                member={member}
                messages={messages}
                lead={callData.call.id}
                type="call"
                client={callData.call}
              />
            )}
            {view === ViewTypes.notes && (
              <LeadNotes
                org={org}
                member={member}
                notes={notes}
                lead={callData.call.id}
                type="call"
              />
            )}
          </div>

          <aside className="lg:col-start-3 lg:col-span-1 space-y-6">
            <section
              aria-labelledby="client-title"
              className="lg:col-start-3 lg:col-span-1"
            >
              <div className="bg-white px-4 py-5 border border-gray-300 sm:rounded sm:px-6">
                <h2
                  id="client-title"
                  className="text-lg font-bold text-gray-900"
                >
                  Information
                </h2>
                <div className="mt-3 flow-root text-sm space-y-2">
                  {callData.call.due && reminderSection}
                  <div className="flex items-center">
                    <p className="pt-2 flex items-center text-md font-bold text-gray-900 mb-2 md:mb-0">
                      <span className="mr-2">Status: </span>
                      <Status
                        value={callData.call.status.toUpperCase()}
                        type="call"
                      />
                    </p>
                  </div>
                  <div className="flex items-center">
                    {callData.call.type === 'incoming' ? (
                      <PhoneArrowDownLeftIcon
                        className="flex-shrink-0 mr-1 h-4 w-4 text-purple-600"
                        aria-hidden="true"
                      />
                    ) : (
                      <PhoneArrowUpRightIcon
                        className="flex-shrink-0 mr-1 h-4 w-4 text-purple-600"
                        aria-hidden="true"
                      />
                    )}
                    <p className="font-medium text-gray-500 col-span-3">
                      {' '}
                      Called on:{' '}
                      <time dateTime={callData.call.createdAt}>
                        {' ' +
                          format(
                            new Date(callData.call.createdAt),
                            'do MMM yyyy',
                          ) +
                          ' at ' +
                          format(new Date(callData.call.createdAt), 'p')}
                      </time>
                    </p>
                  </div>
                  {state.externalData ? (
                    <div className="flex items-center">
                      <ClockIcon
                        className="flex-shrink-0 mr-1 h-4 w-4 text-purple-600"
                        aria-hidden="true"
                      />
                      <p className="font-medium text-gray-500 col-span-3">
                        Duration: {state.externalData.call.duration} seconds
                      </p>
                    </div>
                  ) : null}
                  {callID && recordings && recordings.length > 0 ? (
                    <div
                      className="flex items-center"
                      key={recordings[0].mediaUrl}
                    >
                      <MicrophoneIcon
                        className="flex-shrink-0 mr-1 h-4 w-4 text-purple-600"
                        aria-hidden="true"
                      />
                      <audio controls src={recordings[0].mediaUrl}></audio>
                    </div>
                  ) : null}
                  {state.externalData && state.externalData.recordings ? (
                    <>
                      {state.externalData.recordings.map((recording, key) => (
                        <div className="flex items-center" key={recording.uri}>
                          <MicrophoneIcon
                            className="flex-shrink-0 mr-1 h-4 w-4 text-purple-600"
                            aria-hidden="true"
                          />
                          <audio controls src={recording.mediaUrl}></audio>
                        </div>
                      ))}
                    </>
                  ) : null}
                  {callData.call.employee && (
                    <div className="flex items-center">
                      {callData.call.type === 'incoming' ? (
                        <PhoneArrowDownLeftIcon
                          className="flex-shrink-0 mr-1 h-4 w-4 text-purple-600"
                          aria-hidden="true"
                        />
                      ) : (
                        <PhoneArrowUpRightIcon
                          className="flex-shrink-0 mr-1 h-4 w-4 text-purple-600"
                          aria-hidden="true"
                        />
                      )}
                      <p className="font-medium text-gray-500 col-span-3">
                        {' '}
                        Called by: {callData.call.employee.name}
                      </p>
                    </div>
                  )}
                  <div className="flex items-center">
                    {callData.call.type === 'incoming' ? (
                      <PhoneArrowDownLeftIcon
                        className="flex-shrink-0 mr-1 h-4 w-4 text-purple-600"
                        aria-hidden="true"
                      />
                    ) : (
                      <PhoneArrowUpRightIcon
                        className="flex-shrink-0 mr-1 h-4 w-4 text-purple-600"
                        aria-hidden="true"
                      />
                    )}
                    {callData.call.type === 'incoming' ? (
                      <p className="font-medium text-gray-500 col-span-3 truncate">
                        Phone:{' '}
                        <button
                          type="button"
                          onClick={() => {
                            ui.setCall(true, {
                              to:
                                callData.call.payload.from ??
                                callData.call.payload.number,
                              processMissed: callData.call.id,
                            });
                          }}
                        >
                          <span className="text-purple-700 underline">
                            {callData?.call?.payload?.from ??
                              callData.call.payload.number}
                          </span>
                        </button>
                      </p>
                    ) : (
                      <button
                        type="button"
                        onClick={() => {
                          ui.setCall(true, {
                            to: callData.call.payload.number,
                            processMissed: callData.call.id,
                          });
                        }}
                      >
                        <span className="text-purple-700 underline">
                          {callData.call.payload.number}
                        </span>
                      </button>
                    )}
                  </div>
                  {callData.call.user && (
                    <div className="flex items-center">
                      <AtSymbolIcon
                        className="flex-shrink-0 mr-1 h-4 w-4 text-purple-600"
                        aria-hidden="true"
                      />
                      <p className="font-medium text-gray-500 col-span-3 truncate">
                        Email:{' '}
                        <span className="text-purple-700 underline">
                          {callData.call.user.email}
                        </span>
                      </p>
                    </div>
                  )}
                  {labels && labels.length > 0 ? (
                    <div className="flex items-center flex-wrap space-x-1">
                      <div className="flex items-center mb-1">
                        <TagIcon
                          className="flex-shrink-0 mr-1 h-4 w-4 text-purple-600"
                          aria-hidden="true"
                        />
                        <p className="font-medium text-gray-500 col-span-3 truncate">
                          Tags:{' '}
                        </p>
                      </div>
                      <div className="flex items-center flex-wrap">
                        {labels.map((x) => {
                          return <Label key={x.id} x={x} />;
                        })}
                      </div>
                    </div>
                  ) : null}

                  {/* {upcoming && upcoming.length > 0 && (
                    <div className='space-y-2 pt-4'>
                      <h2
                        className="text-md font-bold text-gray-900"
                      >
                        Upcoming Appointments
                      </h2>
                      {upcoming.map((x, i) => (
                        <button type='button' className='flex items-center text-purple-600 hover:text-purple-700' key={`${i}-${x.id}`} onClick={() => {
                          ui.setPanel(true, {
                            type: "appointment",
                            id: x.id,
                            client: callData.call.user,
                          });
                        }}>
                          <p className="font-medium col-span-3 truncate">
                            {x.entities[0].service.title}
                          </p>
                          <ArrowsPointingOutIcon className="flex-shrink-0 mr-1 h-4 w-4"
                            aria-hidden="true" />
                        </button>
                      ))}
                    </div>
                  )} */}

                  {/* {previous && previous.length > 0 && (
                    <div className='space-y-2 pt-4'>
                      <h2
                        className="text-md font-bold text-gray-900"
                      >
                        Previous Appointments
                      </h2>
                      {previous.map((x, i) => (
                        <button type='button' className='flex items-center text-purple-600' key={`${i}-${x.id}`} onClick={() => {
                          ui.setPanel(true, {
                            type: "appointment",
                            id: x.id,
                            client: callData.call.user,
                          });
                        }}>
                          <p className="font-medium col-span-3 truncate">
                            {x.entities[0].service.title}
                          </p>
                          <ArrowsPointingOutIcon className="flex-shrink-0 mr-1 h-4 w-4 text-purple-600"
                            aria-hidden="true" />
                        </button>
                      ))}
                    </div>
                  )} */}
                </div>
              </div>
            </section>
            <section className="lg:col-start-3 lg:col-span-1">
              <div className="bg-white border border-gray-300 rounded">
                <div className="overflow-hidden font-light text-sm">
                  <div className="divide-y divide-gray-300">
                    <div className="px-3 py-5 sm:px-4">
                      <h2
                        id="actions-title"
                        className="text-lg font-semibold text-gray-900"
                      >
                        More actions
                      </h2>
                    </div>
                    <div className="flex flex-col divide-y divide-gray-300" />
                  </div>
                  <div className="px-3 py-3 sm:px-3 space-y-3">
                    {callData.call.user ? (
                      <button
                        type="button"
                        onClick={() => action(callData.call.user.id)}
                        className="flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"
                      >
                        View Profile
                      </button>
                    ) : null}
                    <button
                      type="button"
                      onClick={() =>
                        setState({ ...state, reminderModal: true })
                      }
                      className="flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"
                    >
                      {callData.call.due ? 'Update reminder' : 'Set reminder'}
                    </button>
                    <button
                      type="button"
                      onClick={() => setState({ ...state, assetModal: true })}
                      className="flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"
                    >
                      Send asset
                    </button>

                    <button
                      type="button"
                      onClick={() =>
                        setState({ ...state, complaintModal: true })
                      }
                      className="flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"
                    >
                      Add to Complaint
                    </button>
                  </div>
                </div>
              </div>
            </section>

            <section aria-labelledby="timeline-title">
              <div className="bg-white px-4 py-5 border border-gray-300 sm:rounded sm:px-6">
                <h2
                  id="timeline-title"
                  className="text-lg font-bold text-gray-900"
                >
                  Timeline
                </h2>

                {/* Activity Feed */}
                <div className="mt-6 flow-root">
                  <ul className="-mb-8">
                    {timeline.map((item, itemIdx) => (
                      <li key={item.id}>
                        <div className="relative pb-8">
                          {itemIdx !== timeline.length - 1 ? (
                            <span
                              className="absolute top-4 left-3 -ml-px h-full w-0.5 bg-gray-100"
                              aria-hidden="true"
                            />
                          ) : null}
                          <div className="relative flex space-x-3">
                            <div>
                              <span
                                className={classNames(
                                  item.type.bgColorClass,
                                  'h-6 w-6 rounded-full flex items-center justify-center',
                                )}
                              >
                                <item.type.icon
                                  className="w-4 h-4 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                            <div className="min-w-0 flex-1 flex justify-between space-x-4 items-center">
                              <div>
                                <p className="text-xs text-gray-500">
                                  {item.content}
                                </p>
                              </div>
                              <div className="text-right text-xs whitespace-nowrap text-gray-500">
                                <time dateTime={item.datetime}>
                                  {item.date}
                                </time>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </section>
          </aside>
        </div>
        {state.newClient && (
          <Modal
            open={state.newClient}
            close={() => setState({ ...state, newClient: false })}
          >
            <NewClientModal
              prefill={{
                phone:
                  callData.call.type === 'incoming'
                    ? (callData.call.payload.from ??
                      callData.call.payload.number)
                    : (callData.call.payload.number ??
                      callData.call.payload.from),
              }}
              onComplete={() => {
                setState({
                  ...state,
                  newClient: false,
                });
              }}
            />
          </Modal>
        )}
        {state.reminderModal && (
          <Modal
            open={state.reminderModal}
            large={true}
            close={() => setState({ ...state, reminderModal: false })}
          >
            <SetReminder
              close={() => setState({ ...state, reminderModal: false })}
              type="call"
              item={callData}
            />
          </Modal>
        )}
        {state.newEnrollment && (
          <Modal
            xlarge
            open={state.newEnrollment}
            close={() => setState({ ...state, newEnrollment: false })}
          >
            <NewEnrollmentModal
              prefill={null}
              setStates={setState}
              metadata={callData.call.metadata}
              onComplete={() => {
                setState({
                  ...state,
                  newEnrollment: false,
                });
              }}
            />
          </Modal>
        )}
        {state.assetModal && (
          <Modal
            open={state.assetModal}
            large={true}
            close={() => setState({ ...state, assetModal: false })}
          >
            <SendAsset
              call={callData && callData.call}
              close={() => setState({ ...state, assetModal: false })}
            />
          </Modal>
        )}
        {state.complaintModal && (
          <Modal
            title="Complaints"
            open={state.complaintModal}
            large={true}
            close={() =>
              setState({
                ...state,
                complaintModal: false,
                selectedComplaint: null,
                newComplaint: false,
              })
            }
          >
            {!complaintData || state.newComplaint ? (
              <AddComplaint
                callData={callData.call}
                client={callData.call?.user}
                dateState={{}}
                closeModal={() =>
                  setState({
                    ...state,
                    complaintModal: false,
                  })
                }
              />
            ) : (
              <div className="overflow-scroll">
                <CallComplaints
                  userId={callID?.user_id}
                  handleComplaint={handleComplaint}
                  selected={state.selectedComplaint}
                  callId={callID?.id}
                />
                <div className="flex justify-end space-x-2">
                  <button
                    className="panel-secondary-button"
                    onClick={() =>
                      setState((s) => ({ ...s, newComplaint: true }))
                    }
                  >
                    Create New Complaint
                  </button>
                  <button
                    disabled={!state.selectedComplaint}
                    onClick={() => {
                      handleAddCall();
                      setState({
                        ...state,
                        complaintModal: false,
                        selectedComplaint: null,
                        newComplaint: false,
                      });
                    }}
                    className={`${
                      !state.selectedComplaint &&
                      '!bg-gray-500 !text-gray-300 !cursor-not-allowed'
                    } transition-all duration-75 ml-4 inline-flex justify-center font-medium rounded-md border border-transparent bg-purple-600 text-white shadow-sm hover:bg-purple-800 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2 py-2 px-3 xl:px-4 text-xs xl:text-sm`}
                  >
                    Add to Complaint
                  </button>
                </div>
              </div>
            )}
          </Modal>
        )}
      </main>
    </div>
  );
}
