import { gql } from '@apollo/client';

export const getTasks = gql`
  query getTasks {
    task(order_by: { due: desc }) {
      priority
      user_id
      updated_at
      title
      status
      data
      id
      due
      created_by
      created_at
      content
      creator {
        given_name
        family_name
        id
      }
      assignee {
        # avatar
        given_name
        family_name
        id
        user_assets {
          assetByAsset {
            url
            # user_assets {
            #   assetByAsset {
            #     url
            #   }
            # }
          }
        }
      }
      comments_aggregate {
        aggregate {
          count
        }
      }
    }
    task_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const getTasksNew = gql`
  query GetTasks($where: task_bool_exp, $limit: Int = 800) {
    tasks: task(where: $where, limit: $limit, order_by: { created_at: desc }) {
      id
      title
      content
      data
      priority
      targetDate
      archived
      status: taskStatus {
        value
      }
      due
      createdBy: created_by
      user: user_id
      type
      createdAt: created_at
      updatedAt: updated_at
      creator {
        given_name
        family_name
        id
        user_assets {
          assetByAsset {
            url
          }
        }
      }
      assignee {
        given_name
        family_name
        id
        user_assets(limit: 1, order_by: { created_at: desc }) {
          assetByAsset {
            url
          }
        }
      }
    }
  }
`;

export const getTasksForEmployee = gql`
  query getTasks($id: uuid!) {
    task(
      order_by: { due: desc }
      where: { _and: [{ user_id: { _eq: $id } }, { archived: { _eq: false } }] }
    ) {
      priority
      user_id
      updated_at
      title
      status
      id
      due
      data
      created_by
      created_at
      content
      creator {
        given_name
        family_name
        id
      }
      assignee {
        given_name
        family_name
        id
      }
    }
    task_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const getTaskById = gql`
  query getTaskById($id: uuid!) {
    task: task_by_pk(id: $id) {
      id
      title
      content
      data
      archived
      priority
      targetDate
      status: taskStatus {
        value
      }
      due
      createdBy: created_by
      user: user_id
      type
      createdAt: created_at
      updatedAt: updated_at
      payments {
        payment_id
      }
      students {
        user_id
      }
      calls {
        call_id
      }
      courses {
        event_id
      }
      leads {
        lead_id
      }
      models {
        session_id
      }
      enrollments {
        enrollment_id
      }
      uploads {
        upload_id
      }
      forms {
        form_id
      }
      comments {
        id
        createdAt: created_at
        updatedAt: updated_at
        content
        user_id
      }
      creator {
        given_name
        family_name
        id
        user_assets(limit: 1, order_by: { assetByAsset: { createdAt: desc } }) {
          assetByAsset {
            url
          }
        }
      }
      assignee {
        given_name
        family_name
        id
        user_assets(limit: 1, order_by: { assetByAsset: { createdAt: desc } }) {
          assetByAsset {
            url
          }
        }
      }
    }
    task_aggregate {
      aggregate {
        count
      }
    }
  }
`;

export const getIncompleteTasksByUser = gql`
  subscription getTasksByUser($id: uuid!, $target: date!) {
    task(
      where: {
        _and: [
          { archived: { _eq: false } }
          { targetDate: { _eq: $target } }
          { user_id: { _eq: $id }, status: { _neq: COMPLETE } }
        ]
      }
    ) {
      id
      title
      content
      data
      priority
      targetDate
      status: taskStatus {
        value
      }
      due
      createdBy: created_by
      user: user_id
      type
      createdAt: created_at
      updatedAt: updated_at
      invoices {
        payment_id
      }
      students {
        user_id
      }
      calls {
        call_id
      }
      courses {
        event_id
      }
      leads {
        lead_id
      }
      models {
        session_id
      }
      uploads {
        upload_id
      }
      comments {
        id
        createdAt: created_at
        updatedAt: updated_at
        content
        user_id
      }
      creator {
        given_name
        family_name
        id
        user_assets {
          assetByAsset {
            url
          }
        }
      }
      assignee {
        given_name
        family_name
        id
        user_assets {
          assetByAsset {
            url
          }
        }
      }
    }
  }
`;

export const getIncompleteTasksByUserQuery = gql`
  query getTasksByUser($id: uuid!, $target: date!) {
    task(
      where: {
        _and: [
          { archived: { _eq: false } }
          { targetDate: { _eq: $target } }
          { user_id: { _eq: $id }, status: { _neq: COMPLETE } }
        ]
      }
    ) {
      id
      title
      content
      data
      priority
      targetDate
      status: taskStatus {
        value
      }
      due
      createdBy: created_by
      user: user_id
      type
      createdAt: created_at
      updatedAt: updated_at
      invoices {
        payment_id
      }
      students {
        user_id
      }
      calls {
        call_id
      }
      courses {
        event_id
      }
      leads {
        lead_id
      }
      models {
        session_id
      }
      uploads {
        upload_id
      }
      comments {
        id
        createdAt: created_at
        updatedAt: updated_at
        content
        user_id
      }
      creator {
        given_name
        family_name
        id
        user_assets {
          assetByAsset {
            url
          }
        }
      }
      assignee {
        given_name
        family_name
        id
        user_assets {
          assetByAsset {
            url
          }
        }
      }
    }
  }
`;

export const addTask = gql`
  mutation addTask($data: task_insert_input!) {
    insert_task_one(object: $data) {
      id
    }
  }
`;

export const updateTaskById = gql`
  mutation updateTaskById($id: uuid!, $data: task_set_input!) {
    update_task_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const getTaskComments = gql`
  query getTaskComments($id: uuid!) {
    task_comment(
      order_by: { created_at: desc }
      where: { _and: [{ task_id: { _eq: $id } }, { archived: { _eq: false } }] }
    ) {
      content
      created_at
      id
      task_id
      data
      updated_at
      user_id
      user {
        full_name
      }
    }
  }
`;

export const addTaskComment = gql`
  mutation addTaskComment($data: task_comment_insert_input!) {
    insert_task_comment_one(object: $data) {
      id
    }
  }
`;

export const updateTaskCommentById = gql`
  mutation updateTaskCommentById($id: uuid!, $data: task_comment_set_input!) {
    update_task_comment_by_pk(pk_columns: { id: $id }, _set: $data) {
      id
    }
  }
`;

export const deleteTaskCommentById = gql`
  mutation deleteTaskComment($id: uuid!) {
    delete_task_comment_by_pk(id: $id) {
      id
    }
  }
`;

export const getIncomingStatus = gql`
  query IncomingStatus {
    status: task_status {
      value
    }
  }
`;

export const insertTaskAsset = gql`
  mutation insertTaskAsset($data: task_asset_insert_input!) {
    insert_task_asset_one(object: $data) {
      id
    }
  }
`;

export const getTaskDueDate = gql`
  query getTaskDueDate($id: uuid!) {
    task: task_by_pk(id: $id) {
      status
      due
    }
  }
`;

export const getTaskAssigneeByTaskId = gql`
  query getTaskAssigneeByTaskId($id: uuid!) {
    task: task_by_pk(id: $id) {
      assignee {
        given_name
        family_name
        id
        user_assets(limit: 1, order_by: { assetByAsset: { createdAt: desc } }) {
          assetByAsset {
            url
          }
        }
      }
    }
  }
`;
