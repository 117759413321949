import { gql } from '@apollo/client';

export const createEmail = gql`
  mutation CreateEmail($data: follow_up_email_insert_input!) {
    insert_follow_up_email_one(object: $data) {
      id
    }
  }
`;

export const insertEmails = gql`
  mutation insertEmails($data: [email_insert_input!]!) {
    insert_email(objects: $data) {
      affected_rows
    }
  }
`;
